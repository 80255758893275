import Box from '@mui/material/Box'
import { SxProps, Theme, styled } from '@mui/material/styles'
import { CSSProperties } from '@mui/material/styles/createMixins'
import clsx from 'clsx'
import React, { FC, useState } from 'react'
import { v4 } from 'uuid'

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bottomOffset' && prop !== 'region',
})<{ bottomOffset: number; region: MapButtonPanelRegion }>(({ theme, bottomOffset, region }) => {
  const baseStyles: CSSProperties = {
    position: 'absolute',
    pointerEvents: 'none',
    transition: theme.transitions.create('bottom', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
  }

  switch (region) {
    case 'top':
      return {
        ...baseStyles,
        top: 24,
        left: 24,
        width: 'calc(100% - 192px)',
      }
    case 'right':
      return {
        ...baseStyles,
        right: 24,
        bottom: 24,
        height: `calc(100% - ${48 + bottomOffset}px)`,
        flexDirection: 'column-reverse',
      }
    case 'bottom':
      return {
        ...baseStyles,
        bottom: 24,
        left: 24,
        width: 'calc(100% - 192px)',
      }
    case 'left':
      return {
        ...baseStyles,
        left: 24,
        bottom: 24,
        height: `calc(100% - ${48 + bottomOffset}px)`,
        flexDirection: 'column-reverse',
      }
  }
})

export type MapButtonPanelRegion = 'bottom' | 'left' | 'top' | 'right'

type MapButtonPanelProps = {
  region: MapButtonPanelRegion
  startElements?: React.ReactElement[]
  endElements?: React.ReactElement[]
  bottomOffset?: number
  sx?: SxProps<Theme>
}

export type ItemAlignment = 'start' | 'end'

export const MapButtonPanel: FC<React.PropsWithChildren<MapButtonPanelProps>> = (props) => {
  const bottomOffset = props.bottomOffset || 0
  const [panelId] = useState(v4())
  const direction = props.region === 'left' || props.region === 'right' ? 'column' : 'row'
  return (
    <StyledBox
      bottomOffset={bottomOffset}
      region={props.region}
      display="flex"
      justifyContent="space-between"
      rowGap={2}
      marginBottom={bottomOffset + 'px'}
      sx={props.sx}
    >
      <Box display="flex" gap={2} flexDirection={direction} style={{ pointerEvents: 'all' }}>
        <>
          {props.startElements?.map((element, index) =>
            React.cloneElement(element, {
              key: 'panel_' + panelId + '_startElement_' + index,
              region: props.region,
              bottomoffset: bottomOffset,
            })
          )}
        </>
      </Box>
      <Box display="flex" gap={2} flexDirection={direction} style={{ pointerEvents: 'all' }}>
        <>
          {props.endElements?.map((element, index) =>
            React.cloneElement(element, { key: 'panel_' + panelId + '_endElement_' + index, region: props.region })
          )}
        </>
      </Box>
    </StyledBox>
  )
}
