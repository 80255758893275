import { useEffect, useState, useRef } from 'react'

import { Pause, PlayArrow } from '@mui/icons-material'
import { Box, Checkbox, FormControl, FormControlLabel, IconButton, MenuItem, Select, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Debouncer } from '../../utils/Debouncer'
import TimeSlider from './TimeSlider'
import { useTranslation } from 'react-i18next'

const timePickerDebouncer = new Debouncer()

type PlayerControlsProps = {
  isPaused: boolean
  isCurrentTime: boolean
  time: Date
  multiplier: number
  bufferStartTime: Date | null
  bufferEndTime: Date | null
  height: number
  enableInterpolation: boolean
  onRequestBufferFill: (time: number) => void
  onTimeChanged: (newTime: Date) => void
  onMultiplierChanged: (newMultiplier: number) => void
  onTogglePlayPause: () => void
  onIsDraggingTime?: (isDragging: boolean) => void
  onGoToTime?: (time: Date) => void
  onSkipToTime: (time: Date) => void
  onToggleInterpolation: (enable: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    speedBtn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    controls: {
      display: 'grid',
      gridTemplateColumns: '230px auto 230px',
      alignItems: 'center',
    },
    controlBtn: {
      marginBottom: 1,
      marginTop: 1,
    },
    controlButtons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 90,
    },
    multiplier: {
      display: 'inline-block',
      marginLeft: 10,
    },
    datetimepicker: {
      width: 'auto',
      pointerEvents: 'auto',
      marginLeft: 8,
    },
    dateInput: {
      paddingTop: 6,
      paddingBottom: 6,
    },
    selectInput: {
      paddingTop: 6,
      paddingBottom: 6,
    },
  })
)
const formatDateForInput = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}
const PlayerControls: React.FC<React.PropsWithChildren<PlayerControlsProps>> = (props) => {
  const classes = useStyles()
  const startTime = props.time.getTime() - 12 * 60 * 60 * 1000
  const [time, setTime] = useState<Date | null>(null)
  const timeRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation('kmap', { keyPrefix: 'History' })

  const handleChangeTime = (newDate: Date) => {
    if (newDate <= new Date()) {
      setTime(newDate)
      props.onTimeChanged(newDate)
      handleGoToTime(newDate)
    }
  }

  let marks: any[] = []
  for (let i = 3600; i < 3600 * 24; i = i + 3600 * 2) {
    const t = startTime + i * 1000
    marks.push({ value: i, label: new Date(t).toLocaleTimeString() })
  }

  const handleTimeChanged = (newTime: Date) => {
    props.onGoToTime?.(newTime)
  }

  const handleIsDraggingTime = (isDragging: boolean) => {
    props.onIsDraggingTime?.(isDragging)
  }

  const handleGoToTime = (time: Date) => {
    timePickerDebouncer.bounce(() => {
      setTime(time)
    }, 30)
    if (!props.isPaused) {
      props.onTogglePlayPause()
    }
    props.onGoToTime?.(time)
  }

  useEffect(() => {
    setTime(props.time)
    if (timeRef.current) {
      timeRef.current.value = formatDateForInput(props.time)
    }
  }, [props.time])
  return (
    <Box sx={{ height: props.height }}>
      <div className={classes.controls}>
        <div className={classes.datetimepicker}>
          {time && (
            <TextField
              onBlur={() => {
                if (timeRef.current?.valueAsNumber) {
                  handleChangeTime(new Date(timeRef.current.valueAsNumber + time.getTimezoneOffset() * 60000))
                } else {
                  console.warn('Invalid date in PlayerControls')
                }
              }}
              defaultValue={formatDateForInput(time)}
              variant="filled"
              type="datetime-local"
              inputProps={{
                ref: timeRef,
                className: classes.dateInput,
                step: 60 * 15,
                max: formatDateForInput(new Date()),
              }}
            />
          )}
        </div>
        <div className={classes.controlButtons}>
          <div className={classes.controlBtn}>
            <IconButton onClick={props.onTogglePlayPause} size="large" disabled={props.isCurrentTime}>
              {(props.isPaused && <PlayArrow />) || <Pause />}
            </IconButton>
          </div>
          <FormControl size="small">
            <Select
              variant="filled"
              size="small"
              sx={{ width: 90 }}
              classes={{ filled: classes.selectInput }}
              onChange={(event) => {
                props.onMultiplierChanged(Number(event.target.value))
              }}
              value={props.multiplier}
            >
              <MenuItem value={1}>1X</MenuItem>
              <MenuItem value={2}>2X</MenuItem>
              <MenuItem value={5}>5X</MenuItem>
              <MenuItem value={10}>10X</MenuItem>
              <MenuItem value={50}>50X</MenuItem>
              <MenuItem value={100}>100X</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.enableInterpolation}
                onChange={() => props.onToggleInterpolation(!props.enableInterpolation)}
                value="checkedB"
                color="primary"
              />
            }
            label={t('continuousMovement')}
          />
        </Box>
      </div>
      <div>
        <TimeSlider
          bufferStartTime={props.bufferStartTime}
          bufferEndTime={props.bufferEndTime}
          time={props.time.getTime()}
          onChanged={handleTimeChanged}
          multiplier={props.multiplier}
          onIsDragging={handleIsDraggingTime}
          onGoToTime={handleGoToTime}
          onRequestBufferFill={props.onRequestBufferFill}
          onSkipToTime={props.onSkipToTime}
        />
      </div>
    </Box>
  )
}
export default PlayerControls
